<template>
  <div>
    <ion-header>
      <div class="app-max-width">
        <ion-toolbar class="text-center">
        
          <ion-buttons slot="start">
            <ion-button @click.prevent="goBack()">
              <ion-icon :icon="chevronBack" color="medium"></ion-icon>
            </ion-button>
          </ion-buttons>
          <!--<ion-buttons slot="end">
            <ion-button>
              <ion-icon slot="icon-only" :ios="ellipsisVertical" :md="ellipsisVertical"></ion-icon>
            </ion-button>
          </ion-buttons>-->

          <ion-title class="text-capitalize">{{page.replace('-', ' ')}}</ion-title>
        
        </ion-toolbar>
      </div>
    </ion-header>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonIcon } from '@ionic/vue';
import { chevronBack, ellipsisVertical } from 'ionicons/icons';
import { useRouter } from 'vue-router';
import { useStore } from "vuex";

export default defineComponent({
  props: ['page'],
  name: 'ModalHeader',
  components: {
    IonHeader,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonButton,
    IonButtons,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const appId = computed(() => store.state.appId);

    function goBack() {
      router.go(-1)
    }

    return {
      chevronBack, ellipsisVertical, router, goBack, store, appId, 
    }
  },
  methods: {

  }
});
</script>

<style scoped>
.btn-light {
  color:#222428!important;
  background:#f4f5f8!important;
}  

</style>

