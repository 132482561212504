import axios from 'axios';
// Automatically add CSRF token to every outgoing request
const baseURL = 'https://api.artistwave.com';

const apiClient = axios.create({
    baseURL,
    withCredentials: true
});

export default apiClient;


