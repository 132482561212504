<template>
  <div>  
    <!-- Errors -->
    <div v-if="isError === true">
      <div v-for="error in errors" :key="error">
        <div v-for="el in error" :key="el">
          <ion-item>
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-text color="danger">{{el}}</ion-text>
          </ion-item>
        </div>
      </div>
    </div>

    <div class="ion-padding-bottom">
      <h4>Userame</h4>
      <ion-text>Enter the username of your profile</ion-text>
    </div>
    <ion-item class="ion-text-center">
      <div tabindex="0"></div><!-- bug fix for safari 14 -->
      <ion-input v-model="profileUsername" type="text" placeholder="Type here"></ion-input>
    </ion-item>
    <div class="ion-padding-top ion-text-center">
      <ion-button v-if="profileUsername != null && profileUsername != ''" @click.prevent="storeProfileUsername()">Save</ion-button>
    </div>
  </div>
</template>

<script>
import { IonText, IonInput, IonItem, IonButton, loadingController, toastController } from '@ionic/vue';
import { defineComponent, onMounted, ref } from 'vue';
//import { person } from 'ionicons/icons';
import apiClient from '../services/api';

export default defineComponent({
  name: 'ProfileUsername',
  props: ['store'], 
  components: {
    IonText, IonInput, IonItem, IonButton
  },
  setup(props) {
    const isError = ref(false);
    const errors = ref([]);     
    const profileUsername = ref(null)
    const timeout = { default: 1000 }
    const toastMessage = ref(null)

    onMounted(() => {
      if(props.store.authUser.username != null) {
        profileUsername.value = props.store.authUser.username;
      }
    })

    async function presentLoading() {
      const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Please wait...',
          duration: timeout,
        });

      await loading.present();

      setTimeout(function() {
        loading.dismiss()
      }, timeout);
    }

    async function openToast() {
      const toast = await toastController
        .create({
          //message: '',
          duration: 6000,
          message: toastMessage.value,
          position: 'bottom',
          buttons: [
            {
              icon: 'close',
              role: 'cancel',
              handler: () => {
                
              }
            }
          ]
        })
      return toast.present();
    }

    function storeProfileUsername() {
      presentLoading()
      apiClient.post('/api/update/profile-username', 
        {
          username: profileUsername.value           
        },
        {
          headers: {
            'Authorization':  `Bearer ${props.store.authToken}`
          }
        }).then(response => {
          // Success
          if(response.data.message == 'success') {
            localStorage.setItem('authUser', JSON.stringify(response.data.user));
            this.dataUp(response.data.user)
            toastMessage.value = 'You have updated your profile.'
            //profileUsername.value = authUser.value.username
            openToast()
            isError.value = false
            errors.value = []
          }
          // Fail Validation
          if(response.data.message == 'fail-not-changed') {
            toastMessage.value = 'You must change your username before you can save it.'
            openToast()          
          }
          // Fail Validation
          if(response.data.message == 'fail') {
            toastMessage.value = 'Something went wrong.'
            openToast()          
          }
          // Fail Validation
          if(response.data.message == 'fail-validation') {
            toastMessage.value = 'Something went wrong.'
            openToast()
            isError.value = true
            errors.value = response.data.errors            
          }
          // Fail Auth
          if(response.data.message == 'fail-auth') {
            toastMessage.value = 'You must be signed in to update your profile.'
            openToast()
          }
        }).catch(error => {
          console.log(error)  
          // credentials didn't match
          isError.value = true
          errors.value = error.response.data.errors
      });        
     
    }
    return {
      storeProfileUsername, profileUsername, presentLoading, isError, errors
    }
  },
  methods: {
    dataUp: function(user) {
      var componentData = {
        dataType: 'user',
        data: user
      }

      this.$emit('component-data', componentData)
    }
  } 
});
</script>