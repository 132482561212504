import { ref } from 'vue';
import { useStore } from "vuex";

function doAuth(val) {
	const store = useStore();
	const authUser = ref([]);
	// If auth token is present in local storage and is authenticated
	if(localStorage.getItem('authToken')) {
		// Set auth token in store
		store.commit('setAuthToken', localStorage.getItem('authToken'));
		// Set is auth in store
		store.commit('setIsAuth', true);
		// If authUser
		if(localStorage.getItem('authUser')) {
			authUser.value = JSON.parse(localStorage.authUser)
			// Set auth user in store
			store.commit('setAuthUser', authUser.value);
			if(authUser.value.status == 1) {
				localStorage.setItem('activeUser', true)
				store.commit('setActiveUser', true);
				//			
				return true
			} else {
				localStorage.setItem('activeUser', false)
				store.commit('setActiveUser', false);
				//
				window.location = '/on-boarding'
			}
		}
	} 
	// User is not authenticated - redirect to sign in
	else {
		if(!val) {
			window.location = '/sign-in'
		} else {
			// Only pages available to public should be accessible if not authenticated
			if(val != 'post-single' && val != 'profile') {
				window.location = '/sign-in'
			}
		}
	}
}

export default doAuth;

